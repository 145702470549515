<template>
  <div class="about">
    <sHeader :hasBack="true" :title="'草稿学案'"></sHeader>
    <nBar :userType="'teacher'" :activeItemName="'index'"></nBar>
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <screen
            :list="screenDefaultList"
            :showReset="true"
            @callback="screenChange"
            @resetAll="screenReset"
          />
        </div>
        <div class="modular-item">
          <c-tree
            v-if="showTree"
            :list="treeList"
            :defaultIds="treeDefaultId"
            @callback="clickChapterTree"
          />
        </div>
        <div class="addcase" @click="addCaseFn">添加新学案</div>
      </div>
      <div class="modular-content">
        <van-list
          v-model:loading="caseLoading"
          :finished="caseLoadingFinished"
          finished-text="没有更多了"
          @load="getCaseList"
        >
          <AwaitCase
            :caseInfo="caseInfo"
            @checkAll="checkAll"
            @noCheckAll="noCheckAll"
            @reloadFn="reloadFn"
          ></AwaitCase>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script setup>
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import screen from "@/components/Screen";
import cTree from "@/components/ChapterTree";
import AwaitCase from "../components/AwaitCaseList.vue";
import { apiGetGradesList } from "@/service/grade.js";
import { apiGetClassesList } from "@/service/class.js";
import { apiGetSubjectsList } from "@/service/subject.js";
import { apiGetNewId, apiGetDraftList } from "@/service/teacher.js";
import { apiGetBooksList, apiGetBookTree } from "@/service/book.js";
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { getLocal } from "@/common/js/utils.js";

const router = useRouter();
let screenDefaultList = ref([
  {
    type: "Search",
    label: "标题",
    name: "searchName",
    key: "searchName",
    placeholder: "请输入关键词",
    value: "",
  },
]);

let showTree = ref(false);
let treeList = ref([]);
let treeDefaultId = ref([0, "all"]);
let _academicYear = getLocal("userInfo");

let useInfo = JSON.parse(_academicYear);

let subjectId = ref("");
let gradeId = ref("");
let bookId = ref("");
let searchName = ref("");
let classId = ref("");
let treeId = ref("");

// 学案数据
// 列表loading效果
let caseLoading = ref(true);
// 结束loading效果
let caseLoadingFinished = ref(false);
let caseInfo = ref([]);
let page = 0;
let limit = 10;

const getCaseList = async () => {
  page++;
  let res = await apiGetDraftList({
    page: page,
    limit: limit,
    searchTitle: searchName.value,
    // isToday: "",
    // appointedDate: "",
    subjectId: subjectId.value,
    bookId: bookId.value,
    treeId: treeId.value,
    gradeId: gradeId.value,
    classId: classId.value,
  });

  if (res) {
    caseLoading.value = false;

    res.data.forEach((item, index) => {
      res.data[index].check = false;
    });

    caseInfo.value = caseInfo.value.concat(res.data);

    if (res.data.length < limit) {
      caseLoadingFinished.value = true;
    }
  } else {
    caseLoading.value = false;
    caseLoadingFinished.value = true;
  }
};

// 全选
const checkAll = () => {
  caseInfo.value.forEach((item, index) => {
    caseInfo.value[index].check = true;
  });
};
// 全不选
const noCheckAll = () => {
  caseInfo.value.forEach((item, index) => {
    caseInfo.value[index].check = false;
  });
};

onBeforeMount(async () => {
  await getGradeAPI();
  await getClassListAPI();
  await getSubjectAPI();
  await getBooksList();
  await getBookTree();
  await getCaseList();
});

// 获取年级
const getGradeAPI = async () => {
  let res = await apiGetGradesList();
  if (res) {
    let check = true;
    let checkIndex = null;
    for (let i = 0; i < screenDefaultList.value.length; i++) {
      if (screenDefaultList.value[i].key == "gradeId") {
        check = false;
        checkIndex = i;
      }
    }

    if (check) {
      screenDefaultList.value.push({
        type: "Picker",
        label: "年级",
        name: "gradeId",
        key: "gradeId",
        placeholder: "请选择年级",
        value: "",
        listIndex: null,
        list: res.data,
      });
    } else {
      screenDefaultList.value[checkIndex] = {
        type: "Picker",
        label: "年级",
        name: "gradeId",
        key: "gradeId",
        placeholder: "请选择年级",
        value: "",
        listIndex: null,
        list: res.data,
      };
    }
  }
};

const screenChange = async (options) => {
  if (options) {
    const item = screenDefaultList.value[options.mainIndex];
    switch (item.key) {
      case "searchName":
        searchName.value = item.value;
        await getCaseList();
        break;
      case "gradeId":
        gradeId.value = item.list[item.listIndex].id;
        await getSubjectAPI();
        await getClassListAPI();

        break;
      case "classId":
        classId.value = item.list[item.listIndex].id;
        await getSubjectAPI();

        break;
      case "subjectId":
        // await getBookListAPI(item.list[item.listIndex])
        subjectId.value = item.list[item.listIndex].id;
        await getBooksList();

        break;
      case "bookId":
        bookId.value = item.list[item.listIndex].id;
        await getBookTree();

        break;
    }

    caseInfo.value = [];
    page = 0;
    await getCaseList();
  }
};

// 获取班级
const getClassListAPI = async () => {
  let res = await apiGetClassesList({
    gradeId: gradeId.value,
    academicYear: useInfo.now_academicYear,
  });
  if (res) {
    let check = true;
    let checkIndex = null;
    for (let i = 0; i < screenDefaultList.value.length; i++) {
      if (screenDefaultList.value[i].key == "classId") {
        check = false;
        checkIndex = i;
      }
    }

    if (check) {
      screenDefaultList.value.push({
        type: "Picker",
        label: "班级",
        name: "classId",
        key: "classId",
        placeholder: "请选择班级",
        value: "",
        listIndex: null,
        list: res.data,
      });
    } else {
      screenDefaultList.value[checkIndex] = {
        type: "Picker",
        label: "班级",
        name: "classId",
        key: "classId",
        placeholder: "请选择班级",
        value: "",
        listIndex: null,
        list: res.data,
      };
    }
  }
};

// 获取科目
const getSubjectAPI = async () => {
  let res = await apiGetSubjectsList({ gradeId: gradeId.value });
  if (res) {
    if (res) {
      let check = true;
      let checkIndex = null;
      for (let i = 0; i < screenDefaultList.value.length; i++) {
        if (screenDefaultList.value[i].key == "subjectId") {
          check = false;
          checkIndex = i;
        }
      }

      if (check) {
        screenDefaultList.value.push({
          type: "Picker",
          label: "科目",
          name: "subjectId",
          key: "subjectId",
          placeholder: "请选择科目",
          value: "",
          listIndex: null,
          list: res.data,
        });
      } else {
        screenDefaultList.value[checkIndex] = {
          type: "Picker",
          label: "科目",
          name: "subjectId",
          key: "subjectId",
          placeholder: "请选择科目",
          value: "",
          listIndex: null,
          list: res.data,
        };
      }
    }
  }
};

// 获取书本
const getBooksList = async () => {
  const params = {
    gradeId: gradeId.value,
    subjectId: subjectId.value,
  };
  const apiRes = await apiGetBooksList(params);
  if (apiRes) {
    // bookList = apiRes.data;

    let check = true;
    let checkIndex = null;
    for (let i = 0; i < screenDefaultList.value.length; i++) {
      if (screenDefaultList.value[i].key == "bookId") {
        check = false;
        checkIndex = i;
      }
    }

    if (check) {
      screenDefaultList.value.push({
        type: "Picker",
        label: "教材",
        name: "bookId",
        key: "bookId",
        placeholder: "请选择教材",
        value: "",
        listIndex: null,
        list: apiRes.data,
      });
    } else {
      screenDefaultList.value[checkIndex] = {
        type: "Picker",
        label: "教材",
        name: "bookId",
        key: "bookId",
        placeholder: "请选择教材",
        value: "",
        listIndex: null,
        list: apiRes.data,
      };
    }
  }
};

// 目录
const getBookTree = async () => {
  showTree.value = false;
  treeDefaultId.value = [0, "all"];
  const params = {
    bookId: bookId.value,
  };
  const apiRes = await apiGetBookTree(params);
  if (apiRes) {
    showTree.value = true;
    treeList.value = apiRes.data;
  }
};

// 重置按钮
const screenReset = () => {
  location.reload();
};

const clickChapterTree = async (e) => {
  // let res=await apiGetBooksList()
  treeId.value = e.id;

  caseInfo.value = [];
  page = 0;

  await getCaseList();
};

const reloadFn = async () => {
  page = 0;
  caseInfo.value = [];
  await getCaseList();
};

// 添加新学案
const addCaseFn = async () => {
  let res = await apiGetNewId();
  if (res) {
    router.push({
      path: "/teacher/settingBasis",
      query: { case_id: res.data.case_id },
    });
  }
};
</script>
<style lang="less" scoped>
@import "@/common/style/caseList.less";

.modular-base {
  .addcase {
    background-color: rgb(102, 204, 255);
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }
}

.modular-content {
  padding: 0 10px;
}
</style>
