<template>
  <ul class="caseList">
    <div v-show="!showSelect" @click="changeShowSelect" style="cursor: pointer">
      批量操作
    </div>
    <div class="selectAllBox" v-show="showSelect">
      <span @click="selectAll">全选</span>
      <span @click="noSelect">全不选</span>
      <span @click="deleCase">删除</span>
      <span @click="cancelSelect">取消</span>
    </div>
    <li @click="gotoDetail" v-for="item in caseInfo">
      <div v-show="showSelect" class="checkboxBox">
        <van-checkbox
          v-model="item.check"
          @change="changeSelect(item.case_id, $event)"
        ></van-checkbox>
      </div>
      <div class="title">
        <h3>{{ item.title }}</h3>
        <!-- <van-image
          width="40"
          height="40"
          :src=""
        /> -->
        <img
          :src="require('@/assets/images/new.png')"
          style="width: 40px; height: 40px"
        />
      </div>
      <ul class="info">
        <li class="classTitle">
          <div>发布班级</div>
          <div class="classList">
            <span style="margin-right: 10px" v-for="subItem in item.class_list"
              >{{ subItem.gradeName }}（{{ subItem.className }}）</span
            >
          </div>
        </li>
        <li>
          <div>开始时间</div>
          <div>{{ item.start_time }}</div>
        </li>
        <li>
          <div>结束时间</div>
          <div>{{ item.blocking_time }}</div>
        </li>
      </ul>
      <hr />
      <div class="functionBtn">
        <van-button type="primary" size="mini" @click="changeCase(item.case_id)"
          >修改学案</van-button
        >
        <van-button type="primary" size="mini" @click="delCase(item.case_id)"
          >删除学案</van-button
        >
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { apiDelDraftList } from "@/service/teacher.js";
import { Dialog, Toast } from "vant";

defineProps(["caseInfo"]);
let emit = defineEmits(["checkAll", "noCheckAll", "reloadFn"]);

let showSelect = ref(false);

const router = useRouter();

const changeShowSelect = () => {
  showSelect.value = true;
  // emit("screenChange");
};

const delCase = async (id) => {
  let formData = new FormData();
  formData.append("case_ids", id);
  let res = await apiDelDraftList(formData);
  if (res) {
    Toast.success("删除成功");
    emit("reloadFn");
  }
};

const selectAll = () => {
  emit("checkAll");
};
const noSelect = () => {
  emit("noCheckAll");
};
const deleCase = () => {
  Dialog.confirm({
    title: "删除学案",
    message: "学案删除后无法恢复",
  })
    .then(async () => {
      // on confirm
      if (!deleteCaseArr.length) {
        Toast.fail("请选择要删除的学案");
        return false;
      }
      let formData = new FormData();
      formData.append("case_ids", deleteCaseArr.join(","));
      let res = await apiDelDraftList(formData);
      if (res) {
        deleteCaseArr = [];
        Toast.success("删除成功");
        emit("reloadFn");
      }
    })
    .catch(() => {
      // on cancel
    });
};

let deleteCaseArr = [];
const changeSelect = (id, e) => {
  if (e) {
    deleteCaseArr.push(id);
  }
  if (!e) {
    let i = deleteCaseArr.indexOf(id);
    deleteCaseArr.splice(i, 1);
  }
};

// 取消按钮
const cancelSelect = () => {
  showSelect.value = false;
  noSelect();
};

const gotoDetail = () => {
  //
};

const changeCase = (id) => {
  router.push({ path: "/teacher/settingBasis", query: { case_id: id } });
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.caseList > li {
  .bs();
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}
.caseList {
  padding-top: 10px;
  .title {
    display: flex;
    align-items: center;
    h3 {
      margin-right: 30px;
    }
  }
  li {
    background-color: rgb(237, 237, 237);
    border-radius: 10px;
    padding: 10px;
  }
  .info {
    display: flex;
    justify-content: space-between;
    li {
      div:nth-child(1) {
        margin-bottom: 10px;
      }
    }
    .classTitle {
      width: 15%;
      .classList {
        overflow: hidden;
        text-overflow: ellipsis;
        /* 弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 1;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;
      }
    }
  }

  .functionBtn {
    display: flex;
    justify-content: end;
    :deep(.van-button) {
      position: static;
    }
    :deep(.van-button:before) {
      width: 0;
      height: 0;
    }
  }
}
.selectAllBox {
  span {
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.checkboxBox {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
