<template>
	<div class="screen-box">
		<van-collapse v-model="collapesActive" accordion>
			<van-collapse-item title="筛选" name="1">
				<div v-for="(item, index) in screenList" :key="index" :class="item.type">
					<div v-if="item.type == 'Search'">
						<van-search
							v-model="item.value"
							show-action
							:label="item.label"
							:placeholder="item.placeholder"
							@search="onSearch(index)"
							:style="{'font-size': '10px'}"
						>
							<template #action>
								<div @click="onSearch(index)">搜索</div>
							</template>
						</van-search>
					</div>
					
					<div v-if="item.type == 'Picker'">
						<van-field
							v-model="item.value"
							is-link
							readonly
							name="picker"
							:label="item.label"
							:default-index="item.listIndex"
							:placeholder="item.placeholder"
							@click="toShowPicker(index)"
						/>
					</div>
				</div>
				<div class="btnBox" v-if="showReset">
					<van-button type="warning" size="mini" icon="revoke" plain hairline @click="resetAll">重置</van-button>
				</div>
			</van-collapse-item>
		</van-collapse>
		
		<van-popup v-model:show="showPicker" position="bottom">
			<van-picker
				:columns="pickerColumns"
				:default-index="pickerIndex"
				@confirm="onPickerConfirm"
				@cancel="showPicker = false"
			/>
		</van-popup>
	</div>
</template>

<script>
	import { reactive, toRefs } from 'vue'
	export default {
		props: {
			active: {
				type: String,
				default: '0',
			},
			list: {
				type: Array,
				default: () => []
			},
			showReset: {
				type: Boolean,
				default: false,
			}
		},
		emits: ['callback', 'resetAll'],
		setup(props, ctx) {
			
			const state = reactive({
				collapesActive: props.active,
				screenList: props.list,
				
				screenIndex: null,
				showPicker: false,
				pickerIndex: null,
				pickerColumns: []
			})
			
			const onSearch = (index) => {
				const options = {
					mainIndex: index
				}
				ctx.emit('callback', options)
			}
			
			const toShowPicker = (index) => {
				const screenItem = state.screenList[index]
				let columns = []
				screenItem.list.forEach((item) => {
					if(screenItem.key == 'subjectId'){
						columns.push(item.subjectName)
					}
					if(screenItem.key == 'bookId'){
						columns.push(item.bookName)
					}
          if (screenItem.key == "gradeId") {
              columns.push(item.gradeName);
          }
          if(screenItem.key == 'classId'){
              columns.push(item.className)
          }
				})
				
				state.screenIndex = index
				state.showPicker = true
				state.pickerIndex = screenItem.listIndex
				state.pickerColumns = columns
			}
			
			const onPickerConfirm = (item, index) => {
				const mainIndex = state.screenIndex
				state.showPicker = false
				state.screenList[mainIndex].value = item
				state.screenList[mainIndex].listIndex = index
				
				const options = {
					mainIndex: mainIndex,
					key: state.screenList[mainIndex].key
				}
				ctx.emit('callback', options)
			}
			
			const resetAll = () => {
				ctx.emit('resetAll')
			}
			
			return {
				...toRefs(state),
				onSearch,
				toShowPicker,
				onPickerConfirm,
				resetAll
				// changeScreen,
				// resetScreen
			}
		}
	}
</script>

<style lang="less" scoped >
	@import '../common/style/base.less';
	@import '../common/style/mixin.less';
	
	/* 竖屏 */
	@media screen and (orientation: portrait){
		
	}
	
	/* 横屏 */
	@media screen and (orientation: landscape){
		
	}
	
	.btnBox{
		width: 90%;
		margin: 0 auto;
		padding-bottom: 10px;
		text-align: right;
	}
</style>
<style>
	.screen-box .van-collapse-item__title{
		font-size: 10px;
		padding: 10px;
	}
	.screen-box .van-collapse-item__content{
		padding: 0;
	}
	.screen-box .van-search{
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 10px;
	}
	.screen-box .van-search__content{
		padding-left: 5px;
	}
	.screen-box .van-search__label{
		font-size: 10px;
	}
	.screen-box .van-search__action{
		font-size: 10px;
	}
	.screen-box .Picker .van-field{
		padding: 6px 2px;
	}
	.screen-box .Picker .van-field__label{
		text-align: center;
		font-size: 10px;
		margin-right: 0;
		width: 50px;
	}
	.screen-box .Picker .van-field__value{
		font-size: 10px;
	}
</style>